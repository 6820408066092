
import { atom } from "jotai";
import { proxy } from "valtio";

const activeSelections = atom([]); //objects
const activeSelection = atom(); //object
const activeCanvas = atom(); //ref
const activeStage = atom({ width: 1200, height: 1200 });

const bothState = proxy({
    leftsidebar: false,
    rightsidebar: false,
    productsidebar: false,
    templateMode: true,
    predesignProductMode: false,
    activeSidebar: "designer",
    activeStageName: "template",
    leftsidebar_width: 500,
    predesignsModal: false,
    predesignsModalForce: false,
    productSelectModal: false,
    applyTemplateToProduct: false,
    selectedProductCategory: 'all'
})

const bothAction = {
    setPredesignProductMode: (val) => (bothState.predesignProductMode = val),
    setBothState: (key, val) => (bothState[key] = val),
    setProductSelectModal: (val, forceSelect = false) => {
        bothState.productSelectModal = val;
        bothState.predesignsModalForce = forceSelect;
    },
    setPredesignsModal: (val) => (bothState.predesignsModal = val),
    toggleLeftSidebar: () => (bothState.leftsidebar = !bothState.leftsidebar),
    toggleRightSidebar: () => (bothState.rightsidebar = !bothState.rightsidebar),
    toggleProductSidebar: () => (bothState.productsidebar = !bothState.productsidebar),
    toggleTemplateMode: () => (bothState.templateMode = !bothState.templateMode),
    setToProductMode: (openLeft = false) => {
        bothState.leftsidebar = openLeft;
        bothState.rightsidebar = false;
        bothState.templateMode = false;
    },
    setToTemplateMode: (openLeft = true) => {
        bothState.leftsidebar = openLeft;
        bothState.rightsidebar = false;
        bothState.templateMode = true;
        bothState.activeStageName = "template";
    },
    setActiveSidebar: (sidebar) => {
        bothState.leftsidebar = true;
        bothState.activeSidebar = sidebar;
    },
    setActiveStageName: (name) => {
        bothState.activeStageName = name;
    },
}

export {
    bothAction, bothState, activeSelections, activeSelection, activeCanvas, activeStage
};

